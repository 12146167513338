html, body { 
  position: relative;
  max-width: 100%; 
  overflow-x: hidden; 
}

body {
  /* background: url("../public/images/stars_bkg.png"); */
  background: #000;
  font-family: 'Roboto';
}

.__hidden {
  visibility: hidden;
}

@keyframes rounding {
  from {
    transform: rotate(359deg);
  }
  25% {
    transform: rotate(270deg);
  }
  50% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(1deg);
  }
}

@keyframes roundingReverse {
  from {
    transform: rotate(-359deg);
  }
  25% {
    transform: rotate(-270deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(-1deg);
  }
}

.rotate--anim {
  animation: rounding;
  animation-duration: 11s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.rotate--anim--reverse {
  animation: roundingReverse;
  animation-duration: 11s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-input-placeholder{
  text-align:right;
  font-size: 20px;
  font-weight: 300;
  margin-top: 4px;
}

video::-webkit-media-controls {
  display:none !important;
}

.body--unfixed {
  min-height: 160vh;
}

.body--fixed {
  height: 100%;
  overflow: hidden;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  padding-top: 30%;
}

.dex--header {
  height: 60px;
  width: 100%;
  position:fixed;
  display: flex;
  justify-content: space-between;
  background: #000;
  z-index: 220;
}

.logo--section {
  width: 221px;
  margin: 10px;
}

.menu--section {
  display: flex;
  color: #FFF;
  margin: 10px;
}

.menu--section .menu--item--first {
  border-left: 2px solid #FFF;
}

.menu--section .menu--item {
   margin: 10px 0px;
   padding: 0px 30px;
   border-right: 2px solid #FFF;
}

.wallet--section {
  margin: 10px;
}


.menu--item a {
  color: #FFF;
  text-decoration: none;
}

.main--submenu {
  position: absolute;
  width: 200px;
  margin-left: -16px;
  background: transparent;
  border-radius: 6px;
  display: none;
}

.main--submenu a {
  width: 100%;
  margin-left: 16px;
}

.submenu--item {
  padding-top: 16px;
  width: 100%;
  min-height: 32px;
  cursor: pointer;
  background: #352F44;
}

.submenu--item--first {
  margin-top: 8px;
  border-radius: 6px 6px 0 0;
}

.submenu--item--last {
  border-radius: 0 0 6px 6px;
}

.submenu--item:hover,
.submenu--item:focus,
.submenu--item:hover a,
.submenu--item:focus a {
  background: #ACF800;
  color: #000;
}

.menu--item:hover .main--submenu,
.menu--item:focus .main--submenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.starmap--btn a {
  border: 1px solid #00F0FF;
  border-radius: 4px;
  padding: 4px 12px;
  color: #00F0FF;
  text-transform: uppercase;
}

.starmap--btn a:hover,
.starmap--btn a:focus {
  background: #00F0FF;
  border-radius: 4px;
  padding: 4px 12px;
  color: #000;
}

.green--btn a {
  background: #ACF800;
  border-radius: 4px;
  padding: 4px 12px;
  color: #000;
  text-transform: uppercase;
}

.connectWallet--btn {
  width: 160px;
  border-radius: 40px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  padding-top: 7px;
  background: #ACF800;
  color: #1E1E1E;
  cursor: pointer;
  user-select: none;
}

.wallet--connected {
  width: 160px;
  border-radius: 40px;
  height: 30px;
  font-size: 16px;
  text-align: center;
  padding-top: 7px;
  color: #FFF;
  background: #352F44;
}

.user--menu {
  height: 120px;
  margin-top: -60px;
  padding-right: 30px;
  background: #352F44;
  color: #FFF;
  border-radius: 40px;
  display: none;
}

.user--menu p {
  padding-top: 72px;
  padding-left: 28px;
  cursor: pointer;
}

.wallet--connected:hover .user--menu,
.wallet--connected:focus .user--menu,
.wallet--connected:active .user--menu {
  display: block;
}

.presale--body {
  display: flex;
  width: 100%;
  max-width: 1292px;
  margin-left: auto;
  margin-right: auto;
}

.star--section {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0px;
}

.star--image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.star--section img,
.star--section video {
  width: 100%;
  max-width: 620px;
  pointer-events: none;
}

.token--selector {
  width: 100%;
  height: 32px;
  margin-top: 52px;
  display: flex;
  justify-content: center;
}

.currency--selector {
  width: 100%;
  height: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.selected--token {
  display: flex;
  margin: 0 10px;
}

.max--value--btn {
  margin-top: 18px;
  color: #4DA1A3;
  font-weight: 700;
  font-size: 12px;
}

.selected--token img {
  margin-top: 10px;
}

.selected--token p {
  margin-left: 10px;
  font-weight: 300;
  font-size: 16px;
  color: #F1F6F9;
}

/* VRP, VDAO - checkbox */

.token--selector .token {
  font-size: 18px;
  font-family: "RoundsBlack";
  text-transform: uppercase;
  margin: 0 10px;
}

.token--vrp {
  color: #96E6A1;
  background: transparent;
  text-shadow: 0 0 20px #D4FC79;
}

.token--vdao {
  background: linear-gradient(2.11deg, #AF15FF 4.13%, #3D217F 147.4%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px 20px #3E12F0;
}

.inActive {
  color: #D9D9D94D;
  -webkit-text-fill-color:#D9D9D94D !important;
  background: none;
  text-shadow: none;
}

.switch--token {
  display: block;
  width: 60px;
  height: 34px;
  margin-top: -6px;
  cursor: pointer;
}

.switch--token input {
  display:none;
}

.checker {
  cursor: pointer;
  display: block;
  background: linear-gradient(to left, #1A192E, #352F44);
  width: 100%;
  height: 30px;
  box-shadow: 0px 0px 5px 2px #00000026 inset;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40px;
}

.checker:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  margin-top: 2px;
  background: linear-gradient(97.85deg, #D4FC79 3.64%, #96E6A1 94.38%);
  box-shadow: 0px 0px 30px #AAF765;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 40px;
}

input:checked + .checker {
  background: linear-gradient(to right, #1A192E, #352F44);;
}

input:checked + .checker:before {
  background: linear-gradient(180deg, #667EEA 0%, #764BA2 100%);
  transform: translateX(30px);
}

/* VRP, VDAO - checkbox end */


.sale--timer {
  font-family: "RoundsBlack";
  font-weight: 700;
  font-size: 32px;
  color: #ACF801;
  text-transform: uppercase;
  margin-top: -16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal--message {
  width: 100%;
  margin: 20px 0;
  text-align: center;
  text-transform: uppercase;
}


.rise--anim {
  position: absolute;
  z-index: 213;
}

.rise--anim img {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 216;
}

/* Right column */


.buy--section {
  margin-top: 100px;
  width: 38%;
  margin-bottom: 200px;
}

.value--subsection {
  width: 100%;
  max-width: 486px;
  border: 1px solid #2E2E2E;
  border-radius: 20px;
  display: flex;
}

.buy--column--left {
  width: 60%;
  border-right: 1px solid #2E2E2E;
}

.buy--column--right {
  width: 40%;
  /* border-left: 1px solid #2E2E2E; */
}

.buy--column--heading,
.stage--heading {
  width: 100%;
  font-family: "RoundsBlack";
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  text-transform: uppercase;
  color: #FFF;
  padding: 12px 0;
  border-bottom: 1px solid #2E2E2E;
}

.buy--section input {
  width: calc(100% - 14px);
  height: 40px;
  margin-left: 3px;
  text-align: right;
  color: #1E1E1E;
  font-weight: 300;
  font-size: 20px;
  text-align: right;
}


.amount--calculator {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin: 3px;
}

.number--key {
  width: calc(33% - 4px);
  border: 1px solid #2E2E2E;
  cursor: pointer;
  text-align: center;
  color: #F1F6F9;
  font-size: 14px;
  font-weight: 300;
  text-transform: uppercase;
  height: 29px;
  margin-top: 4px;
  padding-top: 12px;
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;  
}

.backspace--key {
  width: calc(67% - 4px);
}

@media (pointer: fine) {
  .number--key:hover {
    color: #000;
    background: #F1F6F9;
  }
}

@media (pointer: coarse) { 

  .number--key {
    transition-property: none;
  }

  .number--key:focus,
  .number--key:active {
    color: #000;
    background: #F1F6F9;
  }

  .amount--input input {
    background: #F1F6F9;
  }
}


.stage--stats {
  margin: 8px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 300;
  color: #F1F6F9;
}
.stage--stats p {
  line-height: 0.4;
}

.stage--stats b {
  font-weight: 500;
}

.buy--column--cell {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  color: #F1F6F9;
  border-top: 1px solid #2E2E2E;
  padding-top: 12px;
}

.value--subtitle {
   margin-top: 15px;
   font-size: 13px;
}

.value--subtitle.red {
  font-size: 10px;
  color: #FF0000;
  font-weight: 700;
}

.value--subtitle.green {
  font-size: 10px;
  color: #AAF765;
}

.row--1 {
  height: 38px;
}

.row--2 {
  height: 79.5px;
}

.stage--heading {
  height: 38px;
}

.no--border {
  border: none;
}

.confirm--button {
  font-family: "RoundsBlack";
  margin-top: 15px;
  display: flex;
  border: none;
  flex-direction: column;
  align-items: center;
  height: 58px;
  cursor: pointer;
  padding-top: 20px;
  color: #1E1E1E;
  border-radius: 20px;
  font-size: 32px;
  font-weight: 400;
  text-transform: uppercase;
  user-select: none;
}

.confirm--button.vrp {
  background: #ACF801;
}

.confirm--button.vdao {
  background: linear-gradient(2.11deg, #AF15FF 4.13%, #3D217F 147.4%);
  color: #FFF;
}

.opened--arrow {
  position: absolute;
   width: 20px;
   height: 20px;
   margin-top: 48px;
   z-index: -1;
   transform: matrix(-0.7,1,0.8,1,0,0);
   border-radius: 2px;
   display: none;
}

.opened--arrow.active {
  display: block;
}

.opened--arrow.active.vrp {
  background: #ACF801;
}

.opened--arrow.active.vdao {
  background: #AF15FF
}


.timer--vdao {
  color: #9418E1;
  ;
}

.modal--overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #2D2D2D;
  opacity: 0.7;
  z-index: 225;
  display: none;
}

.modal--overlay.active {
  display: block;
}


.modal--window {
    position: fixed;
    width: 336px;
    left: calc(50% - 168px);
    top: calc(50% - 151px);
    z-index: 257;
    background: #FFF;
    border-radius: 40px;
    padding: 40px;
}

.select--row {
  display: flex;
  margin-top: 20px;
  border-radius: 40px;
}

.select--name {
  margin-left: 10px;
  margin-top: 20px;
}

.select--row:hover,
.select--row:focus {
  background: #DBD8E3;
  cursor: pointer;
}

.invest--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  padding: 4px;
  margin-left: -6px;
  max-width: 496px;
  /* transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: linear; */
}

.invest--opened {
  margin: 0px;
  max-width: none;
  max-width: 486px;
  margin-top: 2px;
  /* margin-left: -1px; */
}

.invest--section.opened {
  border: 1px solid #2E2E2E;
  border-radius: 20px;
  padding: 4px;
  margin-top: 30px;
  margin-left: -6px;
  max-width: 496px;
}

.invest--count--section {
  margin-top: 40px;
  border-radius: 20px;
  background:#352F44;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.invest--num {
  color: #FFF;
  text-transform: uppercase;
}

.buy--section input.order--input {
  text-align: left;
  border: none;
  background: transparent;
  color: #FFF;
}

.invest--description {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #FFF;
  text-align: center;
  font-size: 10px;
}


.agree--dot {
  color: #FFF;
  font-size: 10px;
}


.invest--description p {
  width: 80%;
  text-align: center;
}

.agree--dot {
  display: flex;
  margin-top: 36px;
  margin-left: 18px;
}

.agree--label {
  width: 30px;
  height: 30px;
  background: #352F44;
  border-radius: 10px;
}

.agree--checker {
  display: block;
  width: 28px;
  height: 28px;
}

.agree--checker.agreed {
 background-image: url('../public/images/icons/agree_check.svg');
 background-repeat: no-repeat;
 margin-top: 6px;
 margin-left: 4px;
}

.rise--anim, 
.rise--anim img {
  user-select: none;
}

.agree--label input {
  display: none;
}

.agree--dot p {
  margin-left: 6px;
}

.invest--description a,
.agree--dot a {
  color:#AAF765;
  text-decoration: none;
}


.buy--confirm--section {
  display: flex;
  justify-content: space-between;
}

.final--button {
  width: 45%;
}

.btn--disabled {
  opacity: 0.9;
  background: #CFCFCF !important;
  box-shadow: inset 0px 0px 80px #000000;
  color: rgba(30, 30, 30, 0.3) !important;
  pointer-events: none;
}

.mobile--menu {
  display: none;
}

.modal--env {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 790;
}

.success--modal {
  background: no-repeat url('../public/images/success_bkg.svg');
  background-size: 100%;
}

.modal--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
}

.modal--heading,
.modal--description {
  width: 100%;
  text-align: center;
}

.modal--heading {
  font-family: "RoundsBlack";
  font-weight: 400;
  font-size: 64px;
}

.modal--description {
  font-size: 24px;
  color: #F1F6F9;
  margin-top: -50px;
}

.modal--description.vrp a {
  color: #ACF800;
  font-weight: 700;
}

.modal--description.vdao a {
  color: #9418E1;
  font-weight: 700;
}

.modal--heading.vrp {
  color: #ACF800;
}

.modal--heading.vdao {
  color: #9418E1;
}

.window--action-button {
  width: 30%;
  height: 50px;
  text-align: center;
  padding-top: 20px;
  border-radius: 20px;
  margin-top: 40px;
  font-family: "RoundsBlack";
  font-size: 24px;
  cursor: pointer;
}

.window--action-button.vrp {
   background: #ACF800;
   color: #1E1E1E;
}

.window--action-button.vdao {
  background: #9418E1;
  color: #F1F6F9;
}

.balance--row {
  padding-top: 0px;
  border-bottom: none;
  height: 36px;
}


.dsc--btn {
  position: absolute;
  top: 48px;
  margin-left: 32px;
  padding: 8px;
  cursor: pointer;
}

.value--row {
  padding-top: 10px;
}

.left--stage--heading {
  padding-top: 3px;
}

.left--balance--row {
  margin-top: 12px;
}
/* Mobile version */

.walletBalanceWindow {
  background: #352F44;
  color: #FFF;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.walletBalanceWindow div {
  padding: 20px 0;
}

.balanceWindowHeading {
  text-transform: uppercase;
  font-size: 24px;
  color: #ACF800;
}

@media screen and (max-width : 768px) {

  body {
    background: #000;
  }

  .buy--section input {
    width: calc(100% - 16px);
  }

  .menu--section {
    display: none;
  }

  .mobile--menu--ctnr {
    display: flex;
  }

  .presale--body,
  .star--section,
  .buy--section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .token--selector {
    margin-top: 32px;
  }

  .star--section,
  .buy--section {
    width: 100%;
  }

  .sale--timer--heading {
    text-align: center;
    font-size: 36px;
  }

  .timer--clock {
    margin-top: 8px;
    font-size: 48px;
  }

  .amount--input {
    margin: 3px;
    width: 100%;
  }


  .invest--section {
    width: 100%;
  }

  .mobile--menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left:0;
    width: 100%;
    background: #000;
  }

  .mobile--menu.menu--section {
    display: flex;
  }

  .mobile--sub {
    position: fixed;
    bottom: 40px;
    margin-left: -90px;
  }

  .buy--column--heading, .stage--heading {
    font-size: 16px;
    padding: 16px 0;
  }

  .stage--heading {
    height: 30.5px;
  }

  .modal--container {
    width: 90%;
  }

  .modal--heading {
    font-size: 24px;
  }

  .modal--description {
    margin-top: 0;
  }

  .dsc--btn {
    margin-left: 30px;
  }

  .value--row {
    padding-top: 15px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1919px) {
  .main--submenu {
    margin-left: -54px;
  }

  .buy--column--heading, .stage--heading {
    font-size: 20px;
 }
}

@media screen and (max-width : 556px) {
   .logo--section img {
     width: 120px;
   }

   .row--1 {
     height: 45px;
   }

   .row--2 {
      height: 79.5px;
   }

   .balance--row {
     padding: 4px 0;
     height: 39px;
   }

   .star--image {
      margin-top: -32px;
      z-index: -1;
      width: 90%;
   }

   .sale--timer {
     margin-top: -28px;
   }

   .value--subsection {
     margin-top: -140px;
   }

   .value--subsection.confirm--button {
    margin-top: 0px;
  }


   .connectWallet--btn,
   .wallet--connected {
      width: 120px;
      height: 20px;
      padding-top: 4px;
      font-size: 12px;
   }

   .mobile--menu--ctnr {
    width: 100%;
    justify-content: center;
   }

   .menu--item {
     font-size: 16px;
     padding: 0 10px;
   }

   .menu--section .menu--item  {
      padding: 0 10px;
   }

   .main--submenu {
     width: 100px;
   }

   .mobile--sub {
     margin-left: -20px;
     bottom: 40px;
   }

   .final--button {
     width: 70%;
   }

   .buy--section {
     width: 90%;
   }

   .modal--window {
     width: 60%;
     top: 15%;
   }

   .sale--timer--heading {
     font-size: 15px;
  }

  .timer--clock {
    margin-top: 0px;
  }

  .invest--section {
    margin-top: 30px;
  }

  .buy--section input {
    width: calc(100% - 32px);
  }

  .stage--stats p,
  .stage--stats a {
    color: #F1F6F9 !important;
    text-decoration: none !important;
  }

  .value--subtitle {
    font-size: 10px;
  }


  .dsc--btn {
    top: 42px;
    margin-left: 21px;
  }

}

@media screen and (max-width: 361px) {
  .stage--stats p {
    font-size: 9px;
  }
}

/* HD scale */

@media screen and (min-width: 1921px) {
  .presale--body {
     min-width: 1900px;
  }

  .star--section img,
  .star--section video {
    width: 100%;
    max-width: 850px;
  }

  .value--subsection {
    max-width: 800px;
  }
  .invest--section {
    max-width: 810px;
  }

  .number--key {
    width: calc(32% - 4px);
    padding-top: 20px;
    height: 50px;
    font-size: 24px;
  }

  .buy--column--heading,
  .stage--heading {
    font-size: 36px;
  }

  .buy--section input {
    font-size: 28px;
    height: 60px;
  }

  .value--text {
    font-size: 28px;
  }

  .stage--stats {
    font-size: 20px;
  }

  .backspace--key {
    width: calc(66% - 4px);
  }

  .invest--section.opened {
    max-width: 810px;
  }

  .row--1 {
    height: 60px
  }

  .row--2 {
    height: 137.5px;
  }

  .value--subtitle {
    margin-top: 36px;
    font-size: 20px;
  }

  .value--subtitle.red,
  .value--subtitle.green {
    font-size: 16px;
  }

  .stage--heading {
    padding: 16px 0;
    height: 52px;
  }

  .left--balance--row {
    padding: 5px 0;
  }

  .value--row {
    padding-top: 15px;
  }

  .main--submenu {
    margin-left: -55px;
  }
}